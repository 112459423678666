import React, { useEffect, useRef, useState } from 'react';
import './../../App.css';
import './HomePage.css';

import Header from '../../components/Header';
import Footer from '../../components/Footer';
import homeVideo from '../../assets/home.mp4';
import AnimeCardComponent from '../../components/animecard/AnimeCardComponent';
import ApiService from '../../services/ApiService';
import WatchCardComponent from '../../components/watchcard/WatchCardComponent';
import telegram from '../../assets/telegram.png';
import YandexAdComponent from '../../components/YandexAdComponent';
import PlayIcon from '../../assets/ic_play.svg';
import DownloadIcon from '../../assets/download.svg';
import mindIcon from '../../assets/ic_mind.svg';
import leaderboardIcon from '../../assets/ic_leaderboard.svg';
import collectionsIcon from '../../assets/ic_collection.svg';
import { useNavigate, useParams } from 'react-router-dom';
import AppPromo from '../../components/AppPromo';

import telegramIcon from "../../assets/telegramIcon.svg"
import server from "../../assets/serverIcon.svg"
import support from "../../assets/supportIcon.svg"
import RecommendationsComponent from '../../components/RecommendationsComponent';
import ProfilePic from '../../components/profilepic/ProfilePic';
import adTel from '../../assets/adTel.png';
import adApp from '../../assets/ad_app.png';
import adNy from '../../assets/ad_ny.png';
import etycloud from '../../assets/etycloud.svg';
import AnimixPremiumPopup from '../../components/premium/AnimixPremiumPopup';
import Sidebar from '../../components/Sidebar';
import YearlyStats from '../../components/YearStats';

const HomePage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [animeList, setAnimeList] = useState([]);
    const [watchList, setWatchList] = useState([]);
    const [recommendation, setRecommendation] = useState([]);
    const [categories, setCategories] = useState([]);
    const [url, setUrl] = useState(null);
    const [hideBanner, setHideBanner] = useState(false);
    const [loading, setLoading] = useState(true);
    const [showPremium, setShowPremium] = useState(false);
    const [showNy, setShowNy] = useState(false);
    const [loadingMore, setLoadingMore] = useState(false); // New state for loading more
    const [page, setPage] = useState(0); // New state for pagination
    const videoRef = useRef(null);
    const themes = [
        { name: 'null', colors: ['#111111', '#111111'] },
        { name: 'По умолчанию', colors: ['#111111', '#111111'] },
        { name: 'Магический остров', colors: ['#052029', '#160f22', '#131313', '#0e0e0e'] },
        { name: 'Конец Евангелиона', colors: ['#330305', '#1a170c', '#131313', '#0e0e0e'] },
        { name: 'Малиновый закат', colors: ['#1e0624', '#220c18', '#131313', '#0e0e0e'] },
        { name: 'Карибские глубины', colors: ['#061e24', '#0c2219', '#131313', '#0e0e0e'] },
        { name: 'Летающий замок', colors: ['#1d7085', '#13291f', '#131313', '#0e0e0e'] },
        { name: 'Ночь в Токио', colors: ['#1c023a', '#1d1329', '#131313', '#0e0e0e'] },


    ];

    // State to manage current theme
    const [currentTheme, setCurrentTheme] = useState(themes[0]);
    const [account, setAccount] = useState(null);

    const navigate = useNavigate();
    const sessionKey = localStorage.getItem('sessionKey');

    const isMobileDevice = window.innerHeight > window.innerWidth * 1.5;

    var isRecsLoad = false;
    // Load theme from localStorage on mount
    useEffect(() => {
        const savedThemeName = localStorage.getItem('theme');

        if (savedThemeName) {
            const selectedTheme = themes.find(theme => theme.name === savedThemeName);
            if (selectedTheme) {
                setCurrentTheme(selectedTheme);
            }
        }
    }, []);

    // Save theme name to localStorage when theme changes
    useEffect(() => {
        if (currentTheme.name !== "null") {
            localStorage.setItem('theme', currentTheme.name);
        }
    }, [currentTheme]);

    // Handle theme change on user interaction
    const handleThemeChange = (event) => {
        const selectedTheme = themes.find(theme => theme.name === event.target.value);
        if (selectedTheme && selectedTheme.name !== "null") {
            if (localStorage.getItem("subscribed") === "true") {
                setCurrentTheme(selectedTheme); // Update theme state
            }
            else {
                setShowPremium(true)
            }

        }
    };

    useEffect(() => {
        const loadData = async () => {
            try {
                const data = await ApiService.getSelf();
                if (data.type === "success") {
                    setAccount(data.data);
                    await fetchLastWatch();
                }
            }
            catch (e) { }
            await fetchPublic();


        };

        loadData();
    }, []);
    useEffect(() => {


        const loadData = async () => {

            setLoading(false);
            await fetchRecs(page); // Fetch initial recommendations
        };

        document.title = 'animix - Главная';
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);

            // Detect if scrolled to the bottom of the page
            if (
                window.innerHeight + window.scrollY >= document.documentElement.scrollHeight - 100 &&
                !loadingMore
            ) {
                setLoadingMore(true); // Set loading more state
                setPage((prevPage) => prevPage + 1); // Increment page number
                fetchRecs(page + 1); // Fetch more recommendations
            }
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        loadData();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, [page, loadingMore]); // Add dependencies for page and loadingMore

    const fetchPublic = async () => {
        try {
            const data = await ApiService.getPublicAnimeList(0);
            if (data.type === "success") {
                setAnimeList(data.data);
            } else {
                console.error('Error fetching anime list:', data);
            }
        } catch (error) {
            // console.error('Error fetching data:', error);
        }

        // try {
        //     const catData = await ApiService.getPublicCategories();
        //     if (catData.type === "success") {
        //         setCategories(catData.data);
        //     }
        // } catch (error) {
        //     console.error('Error fetching data:', error);
        // }
    };

    const fetchLastWatch = async () => {
        try {
            const watchData = await ApiService.getLastWatch();
            if (watchData.type === "success") {
                setWatchList(watchData.data);
            }
        } catch (error) {
            //    console.error('Error fetching data:', error);
        }
    };

    const fetchRecs = async (page = 0) => {
        try {
            if (!isRecsLoad) {
                isRecsLoad = true;
                const recsArray = await ApiService.getRecs(page); // Pass page to API

                if (recsArray.type === "success") {
                    setRecommendation((prevRecs) => [
                        ...prevRecs,
                        recsArray.data, // Append new recommendations
                    ]);
                }
                setLoadingMore(false); // Reset loading state for more recommendations
            }
        } catch (error) {
            console.error('Error fetching data:', error);
            setLoadingMore(false);
        }
    };

    const handleLoad = () => {
        if (videoRef.current != null) {
            if (videoRef.current.currentTime < 50) {
                videoRef.current.currentTime = 50;
            }
        }
    };

    return (
        <div className="app">

            <Header scrolled={scrollPosition > 0} />

            <div className="home-main-hor-con">

                <Sidebar
                    onThemeChange={setCurrentTheme}
                    showPremiumPopup={() => setShowPremium(true)}
                />

                <div className='feed'>
                    <div className='home-player-top'>
                        {url == null ? (
                            <video key={homeVideo} autoPlay loop muted>
                                <source src={homeVideo} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        ) : (
                            <video key={url} ref={videoRef} onPlay={handleLoad} autoPlay loop muted>
                                <source src={url} type="video/mp4" />
                                Your browser does not support the video tag.
                            </video>
                        )}

                        <div className='home-overlay'>
                            <div className="home-title">
                                <h1 className="title">Поднятие уровня в одиночку</h1>
                                <p>Самый хайповый тайтл последних месяцев</p>
                            </div>
                            <a href={`/anime/1651`} className='actionButton playButton head'>
                                <img src={PlayIcon} alt="Play Icon" className="playIcon" />Смотреть
                            </a>
                        </div>
                    </div>
                    {/* 
                    <div className="full-width-da-block">
                        <img src={adNy} alt="Ad Background" />
                        <h1>Новогодний марафон на анимикс</h1>
                        <p className="da-text">Проведи выходные вместе с нами, мы подготовили подборку новогодних аниме</p>
                        <div>

                            <button className="da-button" onClick={() => navigate("/category/152")}>
                                Смотреть
                            </button>
                           
                        </div>

                    </div> */}



                    <div className='animeblock'>
                        {loading ? (
                            <div className="loader"></div> // Show loader while loading
                        ) : (
                            <>
                                {watchList.length > 0 && (
                                    <div>
                                        <h1>Продолжить просмотр</h1>
                                        <div className="watch-container">
                                            {watchList.map((watchSnippet) => (
                                                <WatchCardComponent key={watchSnippet.id} watchSnippet={watchSnippet} isInteractive={true} />
                                            ))}
                                        </div>
                                    </div>
                                )}

                                {localStorage.getItem("subscribed") == true ? "" : (
                                    <YandexAdComponent blockId={localStorage.getItem("subscribed") ? `` : `R-A-8131234-1`} />
                                )}

                                <div className="full-width-da-block">
                                    <img src={adApp} alt="Ad Background" />
                                    <p className="da-text">Не потеряйте нас. Приложение на ПК и Android или Телеграм. Лучше сайта.</p>
                                    <div>

                                        <button className="da-button" onClick={() => window.open("https://animixapp.ety.one")}>
                                            Скачать
                                        </button>
                                        <button className="da-button" onClick={() => window.open("https://t.me/animixlib")}>
                                            Telegram
                                        </button>
                                    </div>

                                </div>
                                <a className="container-wrapper"  >
                                    <h1 className='anime-title'>Всё аниме</h1>
                                    <div className="anime-line-container-padding">
                                        <div className="anime-line-container">
                                            {animeList.map((anime) => (
                                                <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                                            ))}
                                        </div>
                                        <a href='/all' className="view-all-button">Посмотреть все</a>
                                    </div>
                                </a>
                                {localStorage.getItem("subscribed") == true ? "" : <YandexAdComponent blockId="R-A-8131234-2" />}
                                {recommendation.map((rec, index) => (
                                    <RecommendationsComponent key={index} recs={rec} />
                                ))}
                                {loadingMore && <div className="loader"></div>} {/* Show loader when loading more */}
                            </>
                        )}
                    </div>
                </div>
            </div>

            {/* <div className='show-ny' onClick={() => {setShowNy(true)}}>Мои аниме-итоги 2024 с animix</div> */}

            {localStorage.getItem("app") !== "true" ?   <div className='down-bt' onClick={() => { window.open("https://animixapp.ety.one") }}>
                <img src={DownloadIcon} />
                <p>  Сайт может перестать работать в любой момент. Скачайте приложение тут.</p>
            </div> : ''}
          
            {account && showNy ? <YearlyStats data={account} /> : ''}
            <div style={{
                background: `linear-gradient(135deg, ${currentTheme.colors.join(', ')})`,




                position: "fixed",
                top: 0,
                zIndex: -1,
                left: 0,

                width: "100vw",
                height: "100vh"

            }} />
            <AppPromo />
            <Footer />
            {showPremium ? <AnimixPremiumPopup onClose={() => {
                setShowPremium(false);
            }} /> : ""}
        </div>
    );
};

export default HomePage;
