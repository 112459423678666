import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import ProfilePic from './profilepic/ProfilePic';
import ApiService from '../services/ApiService';

// Import icons
import leaderboardIcon from '../assets/ic_leaderboard.svg';
import collectionsIcon from '../assets/ic_collection.svg';
import categoryIcon from '../assets/ic_category.svg';
import telegramIcon from '../assets/telegramIcon.svg';
import etycloud from '../assets/etycloud.svg';
import YearlyStats from './YearStats';

const Sidebar = ({ onThemeChange, showPremiumPopup }) => {
    const navigate = useNavigate();
    const sessionKey = localStorage.getItem('sessionKey');
    const [account, setAccount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [isMobileDevice, setIsMobileDevice] = useState(false);

    // Define themes internally
    const themes = [
        { name: 'null', colors: ['#111111', '#111111'] },
        { name: 'По умолчанию', colors: ['#111111', '#111111'] },
        { name: 'Магический остров', colors: ['#052029', '#160f22', '#131313', '#0e0e0e'] },
        { name: 'Конец Евангелиона', colors: ['#330305', '#1a170c', '#131313', '#0e0e0e'] },
        { name: 'Малиновый закат', colors: ['#1e0624', '#220c18', '#131313', '#0e0e0e'] },
        { name: 'Карибские глубины', colors: ['#061e24', '#0c2219', '#131313', '#0e0e0e'] },
        { name: 'Летающий замок', colors: ['#1d7085', '#13291f', '#131313', '#0e0e0e'] },
        { name: 'Ночь в Токио', colors: ['#1c023a', '#1d1329', '#131313', '#0e0e0e'] },
    ];

    const [currentTheme, setCurrentTheme] = useState(themes[0]);

    useEffect(() => {
        // Check if mobile device
        const checkMobileDevice = () => {
            setIsMobileDevice(window.innerHeight > window.innerWidth * 1.5);
        };

        // Initial check
        checkMobileDevice();

        // Add resize listener
        window.addEventListener('resize', checkMobileDevice);

        return () => window.removeEventListener('resize', checkMobileDevice);
    }, []);

    useEffect(() => {
        // Load saved theme
        const savedThemeName = localStorage.getItem('theme');
        if (savedThemeName) {
            const selectedTheme = themes.find(theme => theme.name === savedThemeName);
            if (selectedTheme) {
                setCurrentTheme(selectedTheme);
                onThemeChange(selectedTheme);
            }
        }
    }, [onThemeChange]);

    useEffect(() => {
        const fetchAccount = async () => {
            try {
                const response = await ApiService.getSelf();
                if (response.type === "success") {
                    setAccount(response.data);
                }
            } catch (error) {
                console.error('Error fetching account:', error);
            } finally {
                setLoading(false);
            }
        };

        if (sessionKey) {
            fetchAccount();
        } else {
            setLoading(false);
        }
    }, [sessionKey]);

    const handleThemeChange = (event) => {
        const selectedTheme = themes.find(theme => theme.name === event.target.value);
        if (selectedTheme && selectedTheme.name !== "null") {
            if (localStorage.getItem("subscribed") === "true") {
                setCurrentTheme(selectedTheme);
                onThemeChange(selectedTheme);
                localStorage.setItem('theme', selectedTheme.name);
            } else {
                showPremiumPopup();
            }
        }
    };

    // Get online friends
    const getOnlineFriends = (friendsEntities) => {
        return friendsEntities.filter(friend => {
            const lastWatchedTime = new Date(friend.lastWatchedTime);
            const currentTime = new Date();
            const differenceInMinutes = (currentTime - lastWatchedTime) / (1000 * 60);
            return differenceInMinutes <= 5;
        }).slice(0, 3);
    };

    if (isMobileDevice) {
        return null;
    }

    // if (loading) {
    //     return <div className='home-menu'>
    //         <div className="loader"></div>
    //     </div>;
    // }

    return (
        <div className='home-menu'>
            <div className='menu-item' onClick={() => navigate("/leaderboard")}>
                <img src={leaderboardIcon} alt="Leaderboard" />
                <a>Лидерборд</a>
            </div>
            <div className='menu-item' onClick={() => navigate(`/categories`)}>
                    <img src={categoryIcon} alt="Categories" />
                    <a>Категории</a>
                </div>
            {sessionKey && (
                <div className='menu-item' onClick={() => navigate(`/collection/${localStorage.getItem("profileId")}`)}>
                    <img src={collectionsIcon} alt="Collections" />
                    <a>Моя коллекция</a>
                </div>
            )}

            {loading ?  <div className="loader"></div> : 
                  account != null ? (
                    <div>
                        <h3>Друзья в сети</h3>
                      
                        <div className='friends-container'>
                            {(() => {
                                const onlineFriends = getOnlineFriends(account.friendsEntities);
                                return (
                                    <div>
                                        {onlineFriends.length > 0 ? (
                                            onlineFriends.map((friend, index) => (
                                                <div key={index}>
                                                    <a href={`/profile/${friend.nickname}`} className='friend'>
                                                        <ProfilePic isBig={false} account={friend} />
                                                        <p className="rec-anime-name">{friend.nickname}</p>
                                                    </a>
                                                </div>
                                            ))
                                        ) : (
                                            <label style={{ margin: "30px 0" }}>Друзей в сети пока что нет</label>
                                        )}
                                    </div>
                                );
                            })()}
                        </div>
                    </div>
                ) : (
                    <div>
                        <h3>Войдите в аккаунт</h3>
                        <label>И получите полный доступ</label>
                        <div style={{ margin: "30px 0" }}>
                            <a className="loginButton" href="/login">Войти</a>
                        </div>
                    </div>
                )
          }
            
            {showPremiumPopup && <a>Тема сайта</a>}
            {showPremiumPopup && <div> 
                <div className="theme-selector">
                    <select
                        id="theme-dropdown"
                        value={currentTheme.name}
                        onChange={handleThemeChange}
                    >
                        {themes.map((theme, index) => (
                            theme.name !== "null" && (
                                <option key={index} value={theme.name}>
                                    {theme.name}
                                </option>
                            )
                        ))}
                    </select>
                </div>
            </div>
            }


            <a href='https://cloud.ety.one'>
                <img style={{ width: 230 }} src={etycloud} alt="Ety Cloud" />
            </a>

            <a className='fast-link' href="/contact">Контакты</a>
            <a className='fast-link' href="/community">Правила сообщества</a>
            <a className='fast-link' href="/legal">Правообладателям</a>
            <a className='fast-link' href="/privacy">Политика конфиденциальности</a>

            <a className='footerButton' href='https://t.me/animixlib'>
                <img src={telegramIcon} alt="Telegram" /> Мы в Telegram
            </a>
           
          
        </div>
    );
};

export default Sidebar;