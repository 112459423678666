import React, { useEffect, useRef, useState } from 'react';
import placeholderImage from './../../assets/placeholder.png';
import infoIcon from './../../assets/ic_info_i.svg';
import './FramePopup.css';
import { useNavigate } from 'react-router-dom';
import DomainUtils from '../../services/DomainUtils';
import ApiService from '../../services/ApiService';

const FramePopup = ({ availableFrames, selectedFrame, allFrames, handleFrameSelect, closePopup, readonly = false, nickname = "" }) => {

    const navigate = useNavigate();
    const [frames, setFrames] = useState(availableFrames);
    const [myFrames, setMyFrames] = useState(true);
    const [isVisible, setIsVisible] = useState(true);
    const [popup2Message, setPopup2Message] = useState(null); // State for popup2 message
    const popupRef = useRef(null);
    const popup2Ref = useRef(null);

    const handleClickOutside = (event) => {
        if ((popupRef.current && !popupRef.current.contains(event.target)) &&
            (popup2Ref.current && !popup2Ref.current.contains(event.target))) {
            setIsVisible(false);
            if (closePopup) closePopup();
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    // Handle frame validation
    const handleFrameCheck = async (frameId) => {
        try {
            const message = await ApiService.checkFrames(frameId); // Call API service
            setPopup2Message(message.data); // Set the popup2 message
        } catch (error) {
            console.error("Error checking frame:", error);
            setPopup2Message("Ошибка проверки рамки."); // Handle error
        }
    };

    if (!isVisible) return null;

    return (
        <div className="frame-popup-container">
            <div className="frame-popup" ref={popupRef}>
                <h1>Рамки {nickname}</h1>
                {!readonly && (
                    <div className='horizontal-container'>
                        <input
                            type="checkbox"
                            id="myFrames"
                            name="myFrames"
                            style={{ display: "none" }}
                            checked={myFrames}
                            onChange={(e) => {
                                if (e.target.checked) {
                                    setFrames(availableFrames);
                                } else {
                                    setFrames(allFrames);
                                }
                                setMyFrames(e.target.checked);
                            }}
                        />
                        <label className='label-switch' htmlFor="myFrames"></label>
                        <a>Показать только мои</a>
                    </div>
                )}
                <div className='frames-container'>
                    {frames.map(frame => (
                        <div key={frame.id} className={`frame-card ${selectedFrame === frame.id ? 'selected-frame' : ''}`}>
                            <div className="frame-label" onClick={() => {
                                if (myFrames) { handleFrameSelect(frame) }
                                else {
                                    navigate(`/anime/${frame.animeId}`);
                                }
                            }}>
                                <img
                                    src={frame.url ? frame.url.replace("animix.lol", DomainUtils.getDomain()) : placeholderImage}
                                    alt={frame.title}
                                    className="frame-image-back"
                                />
                                <img
                                    src={frame.url ? frame.url.replace("animix.lol", DomainUtils.getDomain()) : placeholderImage}
                                    alt={frame.title}
                                    className="frame-image"
                                />
                                <div className="frame-info">
                                    <h4>{frame.title}</h4>
                                    <p>{frame.desc}</p>
                                </div>
                                <img
                                    style={{ width: 30, opacity: 0.5 }}
                                    src={infoIcon}
                                    className="check-frame-button"
                                    onClick={(e) => {
                                        e.stopPropagation(); // Prevent parent onClick
                                        if(frame.animeId != null)
                                        {

                                            handleFrameCheck(frame.id); // Check frame
                                        }
                                        else
                                        {
                                            setPopup2Message(frame.title + ": Это специальная рамка. Она не выдаётся за просмотр конкретного аниме");
                                        }
                                    }}
                                >

                                </img>
                                {/* {!readonly && (
                               
                                )} */}
                                
                            </div>
                        </div>
                    ))}
                </div>
           
                <div className="close-icon-popup" style={{"marginTop": 120, "marginRight": 20}} onClick={closePopup}>
                    ✖
                </div>
            </div>

            {/* Popup2 for frame check message */}
            {popup2Message && (
                <div className="popup2-container" ref={popup2Ref}>
                    <div className="popup2">
                        <p>{popup2Message}</p>
                        <button className="close-popup2-button" onClick={(e) => {
                            e.stopPropagation(); setPopup2Message(null);


                        }}>Закрыть</button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default FramePopup;
