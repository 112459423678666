import React, { useState, useEffect } from 'react';
import './YearStats.css';

const YearlyStats = ({ data }) => {
  const [currentSlide, setCurrentSlide] = useState(0);
  const [animation, setAnimation] = useState('fade-in');
  const [isShared, setIsShared] = useState(false);
  const [countdown, setCountdown] = useState('');

  const calculateCountdown = () => {
    const now = new Date();
    const targetDate = new Date('January 1, 2025 00:00:00');
    const timeDifference = targetDate - now;

    const days = Math.floor(timeDifference / (1000 * 60 * 60 * 24));
    const hours = Math.floor((timeDifference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
    const minutes = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));
    const seconds = Math.floor((timeDifference % (1000 * 60)) / 1000);

    return `${days}д ${hours}ч ${minutes}м ${seconds}с`;
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(calculateCountdown());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const getRegistrationStoryText = (registrationDate) => {
    const now = new Date();
    const regDate = new Date(registrationDate);
    const diffYears = Math.floor((now - regDate) / (1000 * 60 * 60 * 24 * 365));
    const diffMonths = Math.floor((now - regDate) / (1000 * 60 * 60 * 24 * 30.44));
    const diffDays = Math.floor((now - regDate) / (1000 * 60 * 60 * 24));

    if (diffDays < 30) return `Ты совсем недавно с нами - всего ${diffDays} дней! Добро пожаловать в мир аниме!`;
    if (diffMonths < 12) return `Уже ${diffMonths} месяцев ты часть нашего сообщества. Время летит незаметно!`;
    return `Невероятно! Ты с нами уже ${diffYears} ${diffYears === 1 ? 'год' : diffYears < 5 ? 'года' : 'лет'}! Настоящий ветеран анимикса!`;
  };

  const getAnimeStoryText = (count) => {
    if (count === 0) return "Похоже, этот год только начинается для тебя в мире аниме!";
    if (count < 50) return `За этот год ты сделал первые шаги в мире аниме, посмотрев ${Math.round(count * 20 / 60)} часов контента!`;
    if (count < 200) return `Ты провел ${Math.round(count * 20 / 60)} захватывающих часов в мире аниме в этом году!`;
    if (count < 500) return `Вау! Ты настоящий ценитель аниме - ${Math.round(count * 20 / 60)} часов просмотра говорят сами за себя!`;
    return `Невероятно! Ты посвятил ${Math.round(count * 20 / 60)} часов аниме в этом году. Ты настоящая легенда!`;
  };

  const getFramesStoryText = (count) => {
    if (count === 0) return "У тебя пока нет рамок, но всё впереди!";
    if (count < 5) return `Отличное начало! Ты собрал свои первые ${count} рамки!`;
    if (count < 15) return `Твоя коллекция из ${count} рамок впечатляет!`;
    if (count < 30) return `${count} рамок - это серьезная коллекция! Ты настоящий коллекционер!`;
    return `${count} рамок - это просто невероятно! Ты собрал почти все существующие рамки!`;
  };

  const getFriendsStoryText = (count) => {
    if (count === 0) return "Пока у тебя нет друзей на платформе, но это легко исправить!";
    if (count < 10) return `Ты нашел ${count} единомышленников - отличное начало для общения!`;
    if (count < 50) return `У тебя уже ${count} друзей - настоящее аниме-сообщество!`;
    if (count < 100) return `${count} друзей - с такой компанией никогда не будет скучно!`;
    return `${count} друзей - ты настоящая звезда сообщества!`;
  };

  const getWeeklyActivityText = (count) => {
    if (count === 0) return "На этой неделе ты решил сделать перерыв, и это нормально!";
    if (count < 5) return `${count} серий за неделю - отличный способ расслабиться!`;
    if (count < 15) return `${count} серий за неделю - ты в отличной форме!`;
    if (count < 30) return `${count} серий за неделю - вот это марафон!`;
    return `${count} серий за неделю - ты смотришь аниме как дышишь!`;
  };

  const generateStories = (userData) => [
    
    {
      title: "Твой Год в Аниме",
      content: getAnimeStoryText(userData.watchCount),
      highlight: `${userData.watchCount} серий просмотрено`,
      emoji: "🎬",
      gradient: "purple-blue"
    },
    {
      title: "Твои Достижения",
      content: getFramesStoryText(userData.availablePicFrames.length),
      highlight: `${userData.availablePicFrames.length} рамок собрано`,
      emoji: "🏆",
      gradient: "green-teal"
    },
    {
      title: "Аниме Сообщество",
      content: getFriendsStoryText(userData.friendsList.length),
      highlight: `${userData.friendsList.length} друзей`,
      emoji: "👥",
      gradient: "blue-indigo"
    },
    {
      title: "Недельная Активность",
      content: getWeeklyActivityText(userData.watchCountLastWeek),
      highlight: `${userData.watchCountLastWeek} серий за неделю`,
      emoji: "📅",
      gradient: "violet-purple"
    },
    {
      title: "Твой путь с анимикс",
      content: getRegistrationStoryText(userData.registrationDate),
      highlight: `С нами с ${new Date(userData.registrationDate).toLocaleDateString('ru-RU')}`,
      emoji: "🌟",
      gradient: "purple-blue"
    },
    {
      title: "Твой вклад в анимикс",
      content: userData.subscribed 
        ? userData.subscriptionType === 'MONTHLY'
          ? "Спасибо за ежемесячную поддержку! Благодаря таким как ты, мы можем делать анимикс лучше каждый день!" 
          : "Твоя годовая подписка - это огромный вклад в развитие платформы. Спасибо за доверие!"
        : "К сожалению, ты не поддерживаешь наш проект подпиской... Но знай, что мы тоже тебе благодарны!",
      emoji: "✨",
      gradient: "pink-orange"
    },
    {
      title: "Поделись Достижениями",
      content: "Расскажи друзьям о своем невероятном аниме-годе!",
      isLast: true,
      emoji: "✨",
      gradient: "purple-pink"
    }
  ];

  const stats = generateStories(data);

  useEffect(() => {
    setAnimation('fade-in');
    const timer = setTimeout(() => setAnimation(''), 500);
    return () => clearTimeout(timer);
  }, [currentSlide]);

  const handleNext = () => {
    if (currentSlide < stats.length - 1) {
      setAnimation('slide-out');
      setTimeout(() => {
        setCurrentSlide(currentSlide + 1);
      }, 300);
    }
  };

  const handlePrev = () => {
    if (currentSlide > 0) {
      setAnimation('slide-out');
      setTimeout(() => {
        setCurrentSlide(currentSlide - 1);
      }, 300);
    }
  };

  const handleShare = async () => {
    const registrationDate = new Date(data.registrationDate).toLocaleDateString('ru-RU');
    const shareText = `
    🌟 Мой Год в Аниме 2024 🌟
    📅 На анимикс с: ${registrationDate}
    📺 Просмотрено: ${data.watchCount} серий
    👥 Друзей: ${data.friendsList.length}
    🎭 Рамок: ${data.availablePicFrames.length}
    ⭐️ Последняя неделя: ${data.watchCountLastWeek} серий
    `.trim();
  
    try {
      if (navigator.clipboard) {
        await navigator.clipboard.writeText(shareText);
        alert('Статистика скопирована в буфер обмена!');
        setIsShared(true);
      } else {
        console.error('Clipboard API не поддерживается в этом браузере.');
      }
    } catch (error) {
      console.error('Ошибка при копировании текста:', error);
    }
  };

  const currentStory = stats[currentSlide];

  if (isShared) {
    return null;
  }

  return (
    <div className="yearly-stats">
      <div className={`story-container ${currentStory.gradient}`}>
        <div className="progress-bar">
          {stats.map((_, index) => (
            <div
              key={index}
              className={`progress-segment ${index <= currentSlide ? 'active' : ''}`}
            />
          ))}
        </div>

        <div className={`content-ny ${animation}`}>
          <h2 className="title-ny">
            {currentStory.emoji} {currentStory.title}
          </h2>
          
          <p className="description-ny">
            {currentStory.content}
          </p>
          
          {currentStory.highlight && (
            <div className="highlight">
              {currentStory.highlight}
            </div>
          )}
          
          {currentStory.isLast && (
            <button className="share-btn" onClick={handleShare}>
              Поделиться достижениями
            </button>
          )}
{/* 
          <p className="countdown-timer">
            Осталось до 2025 года: {countdown}
          </p> */}
        </div>

        <div className="navigation">
          <button
            onClick={handlePrev}
            className={`nav-button prev ${currentSlide === 0 ? 'disabled' : ''}`}
            disabled={currentSlide === 0}
            aria-label="Previous slide"
          >
            ←
          </button>
          <button
            onClick={handleNext}
            className={`nav-button next ${currentSlide === stats.length - 1 ? 'disabled' : ''}`}
            disabled={currentSlide === stats.length - 1}
            aria-label="Next slide"
          >
            →
          </button>
        </div>
      </div>
    </div>
  );
};

export default YearlyStats;