import React, { useState, useEffect, useRef } from 'react';
import './../App.css'; // Assuming you have a CSS file for styling
import Header from './../components/Header';
import Footer from '../components/Footer';
import ApiService from '../services/ApiService';
import AnimeCardComponent from '../components/animecard/AnimeCardComponent';
import {useNavigate,  useParams } from 'react-router-dom';
import YandexAdComponent from '../components/YandexAdComponent';
import Sidebar from '../components/Sidebar';

const CategoriesPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [animeList, setAnimeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(0);
    const [thatsAll, setThatsAll] = useState(false);
    const [category, setCategory] = useState(null);
    const [categories, setCategories] = useState([]);
    const [animeIds, setAnimeIds] = useState([]);
    const initialized = useRef(false);
    
    const { id } = useParams();

    const navigate = useNavigate();

    useEffect(() => {
        const fetchPublic = async () => {
          

            try {
                const catData = await ApiService.getPublicCategories();
                if (catData.type === "success") {
                    setCategories(catData.data);
                }
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };

      
        const loadData = async () => {
          
            await fetchPublic();
            setLoading(false); // Set loading to false after data is fetched

        };

        document.title = 'animix - Категории';
        const handleScroll = () => {
            const position = window.pageYOffset;
            setScrollPosition(position);
        };

        window.addEventListener('scroll', handleScroll, { passive: true });
        loadData();

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);




    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />


         
            <div className="content article">
                <Sidebar
                    onThemeChange={() => { }}
                    showPremiumPopup={null}
                />
                <div className='feed'>
                {loading && !category ? (
                    <p>Загружаем...</p>
                ) : (
                    <div className="container-wrapper">
                        <h1 className='tasty-title'>Категории</h1>
                        <div className='tasty-underline'></div>
                        <YandexAdComponent blockId="R-A-8131234-1" />
                        {categories.map((category) => (
                            <a className="container-wrapper" key={category.id} >
                                <h1 className='anime-title'>{category.name}</h1>
                                <div className="anime-line-container-padding">
                                    <div className="anime-line-container">
                                        {category.animeList.map((anime) => (
                                            <AnimeCardComponent key={anime.id} anime={anime} isInteractive={true} />
                                        ))}
                                    </div>
                                    <a onClick={() => { navigate(`/category/${category.id}`) }} className="view-all-button">Посмотреть все</a>

                                </div>

                            </a>
                        ))}
                        {loading && !thatsAll && <p>Загружаем больше...</p>}
                        {thatsAll && <p>Это всё аниме в этой категории.</p>}
                    </div>
                )}
            </div>
            </div>
            <Footer />
        </div>
    );
};

export default CategoriesPage;
