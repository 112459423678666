import React, { useState, useEffect } from 'react';
import './SettingsMenu.css'; // Ensure to create this CSS file
import SkipMarkForm from './SkipMarkForm';
import ApiService from '../../services/ApiService';
import ContentService from '../../services/ContentService';
import AnimixPremiumPopup from '../premium/AnimixPremiumPopup';

const SettingsMenu = ({ visible, videoRef, series, debugInfo, onSpeedChanged, onSrcChanged, onPremium }) => {
    const [quality, setQuality] = useState({ videoQuality: "FULL_HD" });
    const [qualities, setQualities] = useState([]);
    const [speed, setSpeed] = useState(1);
    const [dubbing, setDubbing] = useState('Стандарт');
    const [state, setState] = useState("def");

    useEffect(() => {
        // Fetch the list of qualities for the series when the component loads
        const fetchQualities = async () => {
            try {
                const response = await ApiService.getQualities(series.animeId, series.seriesNum, series.season);

                if (!response?.data || !Array.isArray(response.data)) {
                    throw new Error('Invalid quality data response');
                }

                const validQualities = response.data.filter(quality =>
                    quality?.privateId && typeof quality.privateId === 'string' &&
                    quality?.videoQuality && typeof quality.videoQuality === 'string'
                );

                setQualities(validQualities);

                const savedPrefQuality = localStorage.getItem("savedPrefQuality");
                if (savedPrefQuality) {
                    const matchingQuality = validQualities.find(quality =>
                        quality.videoQuality === savedPrefQuality
                    );

                    if (matchingQuality) {
                        setQuality(matchingQuality);
                        try {
                            const src = await ContentService.fetchVideo(matchingQuality.privateId);
                            if (src && typeof src === 'string') {
                                onSrcChanged(src, matchingQuality.privateId);
                            }
                        } catch (error) {
                            console.error('Quality change failed:', error);
                        }
                    }
                }
            } catch (error) {
                console.error("Error fetching qualities:", error);
                setQualities([]);
            }
        };

        setQuality({ videoQuality: "FULL_HD" });
        setState("def")
        fetchQualities();
    }, [series]);

    const handleSpeedChange = (newSpeed) => {
        setSpeed(newSpeed);
        onSpeedChanged(newSpeed);
        setState('def');
    };

    const handleQualityChange = async (selectedQuality) => {
        setState("def")
        if (!selectedQuality?.privateId || typeof selectedQuality.privateId !== 'string') {
            console.error('Invalid quality selection:', selectedQuality);
            onPremium();
            return;
        }

        try {
            const src = await ContentService.fetchVideo(selectedQuality.privateId);
            if (!src || typeof src !== 'string') {
                throw new Error('Invalid video source received');
            }

            setQuality(selectedQuality);
            localStorage.setItem("savedPrefQuality", selectedQuality.videoQuality);
            onSrcChanged(src, selectedQuality.privateId);

        } catch (error) {
            console.error('Quality change failed:', error);
            onPremium();
        }
    };


    const getDisplayQualityName = (qualityCode) => {
        switch (qualityCode) {
            case 'FULL_HD':
                return '1080p';
            case 'QHD':
                return 'QHD (2K)';
            case 'HD':
                return '720p';
            case 'ULTRA_HD':
                return 'UHD (4K)';
            default:
                return qualityCode; // Fallback to the original code if no match is found
        }
    };
    function getActiveCodec(src) {
        const formats = {
            av01: { patterns: ['av01'], name: 'AV1' },
            hevc: { patterns: ['h265', 'hevc'], name: 'HEVC/H.265' },
            h264: { patterns: ['h264', 'avc'], name: 'H.264' }
        };

        if (!src) return 'Unknown';
        const url = src.toLowerCase();
        const [found] = Object.entries(formats)
            .find(([_, { patterns }]) => patterns.some(p => url.includes(p))) || [];

        return found ? formats[found].name : 'Unknown';
    }

    const renderContent = () => {
        switch (state) {
            case 'def':
                return (<div key="main" className="settings-page">
                    <div className="settings-option" onClick={() => setState('quality')}>
                        <span>Качество</span>
                        <span className={`option-value ${['ULTRA_HD', 'QHD'].includes(quality.videoQuality) ? 'high' : ''}`}> {getDisplayQualityName(quality.videoQuality)}</span>
                    </div>
                    <div className="settings-option" onClick={() => setState('speed')}>
                        <span>Скорость</span>
                        <span className="option-value">{speed}x</span>
                    </div>
                    <div className="settings-option" onClick={() => setState('sound')}>
                        <span>Озвучка</span>
                        <span className="option-value">{dubbing}</span>
                    </div>
                    {localStorage.getItem("editor") === "true" || localStorage.getItem("mod") === "true" ? (
                        <div>
                            <div className="settings-option debug" onClick={() => setState('marks')}>
                                <span>Редактирование меток</span>
                            </div>
                        </div>
                    ) : ""}
                    <div className="settings-option debug" onClick={() => setState('debug')}>
                        <span>Для разработчиков</span>
                    </div>
                </div>);
            case 'speed':
                return (
                    <div key="speed" className="settings-page">
                        <div className="settings-option" onClick={() => handleSpeedChange(1)}>1x</div>
                        <div className="settings-option" onClick={() => handleSpeedChange(1.25)}>1.25x</div>
                        <div className="settings-option" onClick={() => handleSpeedChange(1.5)}>1.5x</div>
                        <div className="settings-option" onClick={() => handleSpeedChange(1.75)}>1.75x</div>
                        <div className="settings-option">
                            <p className="settings-text">Чтобы серия была засчитана, смотрите большую часть на 1x</p>
                        </div>
                    </div>
                );
            case 'quality':
                return (
                    <div key="quality" className="settings-page">
                        {qualities.map((qualityOption) => (
                            <div className="settings-option" key={qualityOption.privateId} onClick={() => handleQualityChange(qualityOption)}>
                                {getDisplayQualityName(qualityOption.videoQuality)}
                            </div>
                        ))}
                    </div>
                );
            case 'marks':
                return (
                    <>
                        <div className="settings-option" onClick={() => setState('def')}>Закрыть</div>
                        <SkipMarkForm videoRef={videoRef} series={series} />
                    </>
                );
            case 'sound':
                return (
                    <>
                        <div className="settings-option" onClick={() => setState('def')}>Стандарт</div>
                    </>
                );
            case 'debug':
          
                const videoElement = videoRef.current;

                // Enhanced codec detection
                const codecSupport = {
                    h264: {
                        mp4: videoElement?.canPlayType('video/mp4; codecs="avc1.42E01E"'),
                        webm: videoElement?.canPlayType('video/webm; codecs="avc1.42E01E"')
                    },
                    h265: {
                        mp4: videoElement?.canPlayType('video/mp4; codecs="hvc1.1.6.L123.00"') ||
                            videoElement?.canPlayType('video/mp4; codecs="hev1.1.6.L123.00"'),
                        webm: videoElement?.canPlayType('video/webm; codecs="hvc1.1.6.L123.00"')
                    },
                    av01: {
                        mp4: videoElement?.canPlayType('video/mp4; codecs="av01.0.05M.08"'),
                        webm: videoElement?.canPlayType('video/webm; codecs="av01.0.05M.08"')
                    }
                };

                const detectedCodecs = [];
                if (codecSupport.h264.mp4 || codecSupport.h264.webm) detectedCodecs.push('H.264');
                if (codecSupport.h265.mp4 || codecSupport.h265.webm) detectedCodecs.push('HEVC/H.265');
                if (codecSupport.av01.mp4 || codecSupport.av01.webm) detectedCodecs.push('AV1');

                const videoStats = {
                    // Player state
                    currentTime: videoElement?.currentTime,
                    duration: videoElement?.duration,
                    buffered: videoElement?.buffered.length > 0 ?
                        `${Math.round(videoElement.buffered.end(0))}s` : 'None',
                    playbackState: {
                        paused: videoElement?.paused,
                        ended: videoElement?.ended,
                        seeking: videoElement?.seeking
                    },
                    networkState: {
                        readyState: videoElement?.readyState,
                        networkState: videoElement?.networkState
                    },
                    error: videoElement?.error?.message || 'None',

                  
                    resolution: videoElement?.videoWidth + 'x' + videoElement?.videoHeight,
                    // Performance metrics
                    performance: {
                        droppedFrames: videoElement?.webkitDroppedFrameCount || 'N/A',
                        decodedFrames: videoElement?.webkitDecodedFrameCount || 'N/A',
                        memoryUsage: performance.memory ?
                            (performance.memory.usedJSHeapSize / 1048576).toFixed(2) + ' MB' : 'N/A'
                    },

                    // Network timing
                    timing: {
                        loadTime: window.performance.timing.loadEventEnd - window.performance.timing.navigationStart,
                        domInteractive: window.performance.timing.domInteractive - window.performance.timing.navigationStart
                    },

                    // Player configuration
                    config: {
                        quality: quality.videoQuality,
                        playbackRate: speed,
                        currentSrc: videoElement?.src?.split('/').pop() || 'Unknown',
                        dubbingTrack: dubbing
                    },

                    // Series context
                    seriesInfo: {
                        animeId: series?.animeId,
                        seriesNum: series?.seriesNum,
                        season: series?.season,
                        episodeTitle: series?.title,
                        debugInfo: debugInfo
                    },

                    // System info
                    environment: {
                        userAgent: navigator.userAgent,
                        platform: navigator.platform,
                        cookiesEnabled: navigator.cookieEnabled,
                        localStorage: {
                            videoQuality: localStorage.getItem("savedPrefQuality"),
                            isEditor: localStorage.getItem("editor"),
                            isMod: localStorage.getItem("mod")
                        }
                    },

                    // Feature detection
                    supportedFeatures: {
                        MSE: 'MediaSource' in window,
                        WebRTC: 'RTCPeerConnection' in window,
                        WebGL: !!document.createElement('canvas').getContext('webgl'),
                        HLS: videoElement?.canPlayType('application/vnd.apple.mpegurl')
                    },

                    // Diagnostics
                    warnings: {
                        qualityIssues: qualities.length === 0 ? 'No qualities available' : 'OK',
                        cors: videoElement?.crossOrigin !== null ? 'Potential CORS issues' : 'CORS configured',
                        autoplay: videoElement?.autoplay ? 'Autoplay enabled' : 'Autoplay blocked'
                    }
                };

                return (
                    <div className="debug-info-container">
                       
                        <button
                            className="copy-debug-btn"
                            onClick={() => setState('def')}
                        >
                            Закрыть
                        </button>
                        <div className="debug-section">
                            <h3>Player State</h3>
                            <pre>{JSON.stringify({
                                time: `${Math.round(videoStats.currentTime)}s / ${Math.round(videoStats.duration)}s`,
                                buffer: videoStats.buffered,
                                playbackState: videoStats.playbackState,
                                networkState: videoStats.networkState,
                                resolution: videoStats.resolution
                            }, null, 2)}</pre>
                        </div>


                        <div className="debug-section">
                            <h3>Player Hook</h3>
                            <pre>{JSON.stringify(debugInfo)}</pre>
                        </div>

                        <div className="debug-section">
                            <h3>Performance Metrics</h3>
                            <pre>{JSON.stringify(videoStats.performance, null, 2)}</pre>
                        </div>

                        <div className="debug-section">
                            <h3>Configuration</h3>
                            <pre>{JSON.stringify(videoStats.config, null, 2)}</pre>
                        </div>

                        <div className="debug-section">
                            <h3>Environment</h3>
                            <pre>{JSON.stringify(videoStats.environment, null, 2)}</pre>
                        </div>

                        <div className="debug-section">
                            <h3>Diagnostics</h3>
                            <pre>{JSON.stringify(videoStats.warnings, null, 2)}</pre>
                        </div>

                        <button
                            className="copy-debug-btn"
                            onClick={() => navigator.clipboard.writeText(JSON.stringify(videoStats, null, 2))}
                        >
                            Копировать информацию для задротов
                        </button>
                    </div>
                );
        }
    }

    return (
        <div className={`settings-menu ${!visible && state !== 'debug' ? 'hidden' : ''}`}>
            {renderContent()}

        </div>
    );
};

export default SettingsMenu;
