// src/pages/AppLoginPage.js
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const AppLoginPage = () => {
    const navigate = useNavigate();

    useEffect(() => {
        // Get the `key` from the URL query parameters
        const urlParams = new URLSearchParams(window.location.search);
        const key = urlParams.get('key');

        if (key) {
            // Save the key in localStorage as sessionKey
            localStorage.setItem('sessionKey', key);
            localStorage.setItem('app', "true");
        }

        // Redirect to home page
        navigate('/');
    }, [navigate]);

    return (
        <div>
            <h2>Logging in...</h2>
        </div>
    );
};

export default AppLoginPage;
