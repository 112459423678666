import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';

import './App.css';
import HomePage from './pages/home/HomePage';
import LoginPage from './pages/LoginPage';
import RegisterPage from './pages/RegisterPage';
import UploadAnimeComponent from './pages/upload/UploadPage';
import LegalPage from './pages/LegalPage';
import NotFoundPage from './pages/NotFoundPage';
import AdminPage from './pages/admin/AdminPage';
import AnimePage from './pages/anime/AnimePage';
import WatchPage from './pages/watch/WatchPage';
import PrivacyPage from './pages/PrivacyPage';
import AllAnimePage from './pages/AllAnimePage';
import CategoryManagementPage from './pages/CategoryManagementPage';
import CategoryPage from './pages/CategoryPage';
import ProfilePage from './pages/profile/ProfilePage';
import CollectionPage from './pages/CollectionPage';
import LeaderboardPage from './pages/leaderboard/LeaderboardPage';
import AdminUsersPage from './pages/admin/AdminUsers';
import ReviewsAdminPage from './pages/admin/ReviewsAdminPage';
import AboutPage from './pages/AboutPage';
import CommunityGuidelinesPage from './pages/CommunityGuidelines';
import ProfileSettingsPage from './pages/profile_settings/ProfileSettingsPage';
import TestWatchPage from './pages/watch/TestWatchPage';
import TestWatchPageAdFox from './pages/watch/TestWatchPageAdfox';
import AdFoxInstreamTest from './pages/watch/AdFoxInstreamTest';
import AdFoxInstreamTest2 from './pages/watch/AdFoxInstreamTest2';
import VastTestPage from './pages/watch/VastTest';
import VastAdPlayer from './pages/watch/VastTest';
import ContactPage from './pages/ContactPage';
import AppLoginPage from './pages/AppLoginPage';
import CategoriesPage from './pages/CategoriesPage';

function App() {
    return (
        <div className="App">
            <Router>
                <Routes>
                    <Route path="/" element={<HomePage/>}/>
                    <Route path="/login" element={<LoginPage/>}/>
                    <Route path="/profile/" element={<ProfilePage/>}/>
                    <Route path="/settings" element={<ProfileSettingsPage/>}/>
                    <Route path="/profile/:nickname" element={<ProfilePage/>}/>
                    <Route path="/all" element={<AllAnimePage/>}/>
                    <Route path="/categories" element={<CategoriesPage/>}/>
                    <Route path="/register" element={<RegisterPage/>}/>
                    <Route path="/upload" element={<UploadAnimeComponent/>}/>
                    <Route path="/legal" element={<LegalPage/>}/>
                    <Route path="/about" element={<AboutPage/>}/>
                    <Route path="/community" element={<CommunityGuidelinesPage/>}/>
                    <Route path="/admin" element={<AdminPage/>}/>
                    <Route path="/admin/users" element={<AdminUsersPage/>}/>
                    <Route path="/admin/reviews" element={<ReviewsAdminPage/>}/>
                    <Route path="/admin/category" element={<CategoryManagementPage/>}/>
                    <Route path="/leaderboard" element={<LeaderboardPage/>}/>
                    <Route path="/privacy" element={<PrivacyPage/>}/>
                    <Route path="/anime/:id" element={<AnimePage/>}/>
                    <Route path="/category/:id" element={<CategoryPage/>}/>
                    <Route path="/collection/:id" element={<CollectionPage/>}/>
                    <Route path="/watch/:id/:seriesId" element={<WatchPage/>}/>
                    <Route path="/testwatch/:id/:seriesId" element={<TestWatchPage/>}/>
                    <Route path="/testwatchadfox/:id/:seriesId" element={<TestWatchPageAdFox/>}/>
                    <Route path="/testad" element={<AdFoxInstreamTest/>}/>
                    <Route path="/testad2" element={<AdFoxInstreamTest2/>}/>
                    <Route path="/vasttest" element={<VastAdPlayer/>}/>
                    <Route path="/contact" element={<ContactPage/>}/>
                    <Route path="/appLogin" element={<AppLoginPage />} /> 
                    <Route path="*" element={<NotFoundPage/>}/>
                </Routes>
            </Router>
        </div>
    );
}

export default App;
