import React, { useState, useEffect, useRef } from 'react';
import './../App.css';
import Header from './../components/Header';
import Footer from '../components/Footer';
import ApiService from '../services/ApiService';
import AnimeCardComponent from '../components/animecard/AnimeCardComponent';
import { useParams } from 'react-router-dom';
import sadCatImage from '../assets/sad_asuka.jpg'; // Import the sad cat image
import Sidebar from '../components/Sidebar';

const CollectionPage = () => {
    const [scrollPosition, setScrollPosition] = useState(0);
    const [animeList, setAnimeList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [selectedCollection, setSelectedCollection] = useState(null);
    const [collections, setCollections] = useState([]);
    const [animeIds, setAnimeIds] = useState([]);
    const [page, setPage] = useState(0); // Track current page for infinite scrolling
    const thatsAll = useRef(false); // Ref to track if there's no more data to load
    const initialized = useRef(false);
    const currentLoadingPage = useRef(-1); // Track current loading page
    const fetchedCollections = useRef(false); // Ensure collections fetch is not repeated
    const { id } = useParams();
    const themes = [
        { name: 'null', colors: ['#111111', '#111111'] },
        { name: 'По умолчанию', colors: ['#111111', '#111111'] },
        { name: 'Магический остров', colors: ['#052029', '#160f22', '#131313', '#0e0e0e'] },
        { name: 'Конец Евангелиона', colors: ['#330305', '#1a170c', '#131313', '#0e0e0e'] },
        { name: 'Малиновый закат', colors: ['#1e0624', '#220c18', '#131313', '#0e0e0e'] },
        { name: 'Карибские глубины', colors: ['#061e24', '#0c2219', '#131313', '#0e0e0e'] },
        { name: 'Летающий замок', colors: ['#1d7085', '#13291f', '#131313', '#0e0e0e'] },
        { name: 'Ночь в Токио', colors: ['#1c023a', '#1d1329', '#131313', '#0e0e0e'] },


    ];
    const [currentTheme, setCurrentTheme] = useState(themes[0]);

    // Load theme from localStorage on mount
    useEffect(() => {
        const savedThemeName = localStorage.getItem('theme');

        if (savedThemeName) {
            const selectedTheme = themes.find(theme => theme.name === savedThemeName);
            if (selectedTheme) {
                setCurrentTheme(selectedTheme);
            }
        }
    }, []);

    let lastPage = -1;
    useEffect(() => {
        const fetchData = async () => {
            if (!fetchedCollections.current) {
                fetchedCollections.current = true;
                const catData = await ApiService.getCollections(id);
                if (catData.type === "success") {
                    setCollections(catData.data);
                    const initialCollection = catData.data[0];
                    setSelectedCollection(initialCollection);
                    const uniqueAnimeIds = Array.from(new Set(initialCollection.animeIds));
                    setAnimeIds(uniqueAnimeIds);

                    thatsAll.current = false; // Reset "thatsAll" status
                }
            }
        };

        fetchData();
    }, [id]);

    const fetchAnimeByPage = async (pageIndex) => {

        const start = pageIndex * 10;
        const idsToFetch = animeIds.slice(start, start + 10);
        if (idsToFetch.length === 0) {
            thatsAll.current = true;
            return;
        }
        if (lastPage == pageIndex) return;
        setLoading(true);
        lastPage = pageIndex;
        const data = await ApiService.getAnimeByIds(idsToFetch);
        if (data.type === "success") {

            setAnimeList((prevAnimeList) => [...prevAnimeList, ...data.data]); // Append new anime data
            setLoading(false);
        }
    };

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY || document.documentElement.scrollTop;
            const scrollHeight = document.documentElement.scrollHeight;
            const clientHeight = document.documentElement.clientHeight;

            if (scrollTop + clientHeight >= scrollHeight - 50 && !loading && !thatsAll.current) {
                setPage((prevPage) => prevPage + 1);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll); // Cleanup listener
    }, [loading, animeIds]);
    useEffect(() => {
        if (page !== currentLoadingPage.current && animeIds.length > 0) {
            currentLoadingPage.current = page;
            fetchAnimeByPage(page);
        }
    }, [page, animeIds]);

    useEffect(() => {
        if (!initialized.current) {
            fetchAnimeByPage(page); // Only call this on the first initialization of a collection
            initialized.current = true; // Ensure it won't be called again when the collection changes
        }
    }, [selectedCollection]);


    const handleCollectionChange = (collection) => {
        if (collection === selectedCollection) return;

        setSelectedCollection(collection);
        const uniqueAnimeIds = Array.from(new Set(collection.animeIds));
        setAnimeIds(uniqueAnimeIds);
        lastPage = -1;
        // Reset the state for the new collection
        setAnimeList([]);
        setPage(0);
        currentLoadingPage.current = -1; // Ensure no conflict with page change
        thatsAll.current = false; // Reset all data

        initialized.current = false; // Allow initial fetch for the new collection
    };
    return (
        <div className="app">
            <Header scrolled={scrollPosition > 0} />
            <div className="content article" style={{
                background: `linear-gradient(135deg, ${currentTheme.colors.join(', ')})`,



                backgroundSize: 'cover',
                backgroundAttachment: 'fixed',

            }} >
                {loading && !selectedCollection ? (
                    <p>Загружаем...</p>
                ) : (
                    <div className="container-wrapper">
                        <Sidebar
                            onThemeChange={setCurrentTheme}
                            showPremiumPopup={null}
                        />
                        <div className='feed'>
                            <h1 className="tasty-title">
                                Коллекции
                            </h1>
                            <div className="tasty-underline"></div>

                            <div className="collection-selectors">
                                {collections.map((collection) => (
                                    <button
                                        style={{ margin: '4px' }}
                                        key={collection.id}
                                        className={selectedCollection === collection ? 'tab-button selected' : 'tab-button'}
                                        onClick={() => handleCollectionChange(collection)}
                                    >
                                        {collection.name}
                                    </button>
                                ))}
                            </div>

                            <div className="anime-container" style={{ marginTop: '28px' }}>
                                {animeList.length > 0 ? (
                                    animeList.map((anime, index) => (
                                        <AnimeCardComponent key={index} anime={anime} isInteractive={true} />
                                    ))
                                ) : (
                                    <div style={{ textAlign: 'center', marginTop: '20px' }}>
                                        <img
                                            src={sadCatImage}
                                            alt="Sad Cat"
                                            style={{ width: '200px', height: 'auto' }}
                                        />
                                        <p>В этой коллекции пока нет аниме</p>
                                    </div>
                                )}
                            </div>
                       
                        {loading && !thatsAll.current && <p>Загружаем больше...</p>}
                        {thatsAll.current && <p>Это всё аниме в этой коллекции.</p>}
                        </div>
                    </div>
                )}
            </div>
            <Footer />
        </div>
    );
};

export default CollectionPage;
